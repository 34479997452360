export const isPWASupported = () => {
	const areCookiesEnabled = () => (typeof navigator === 'object' || navigator.cookieEnabled); 

	const isIndexedDBAvailable = () => {
		try {
			return typeof indexedDB === 'object'
		} catch (e) {
			return false
		}
	}

	return(
		typeof window !== 'undefined' &&
		isIndexedDBAvailable() &&
		areCookiesEnabled() &&
		'serviceWorker' in navigator &&
		'PushManager' in window &&
		'Notification' in window &&
		'fetch' in window &&
		ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
		PushSubscription.prototype.hasOwnProperty('getKey') &&
		!navigator.brave
	)
}

export const isIndexedDBConnectable = () => {
	return new Promise((resolve, reject) => {
		try {
			let preExist = true
			const DB_CHECK_NAME = 'validate-browser-context-for-indexeddb-analytics-module'
			const request = self.indexedDB.open(DB_CHECK_NAME)
			request.onsuccess = () => {
				request.result.close()
				if (!preExist) self.indexedDB.deleteDatabase(DB_CHECK_NAME)
				resolve(true)
			}
			request.onupgradeneeded = () => preExist = false
			request.onerror = () => {
				let _a
				reject(((_a = request.error) === null || _a === void 0 ? void 0 : _a.message) || '')
			}
		} catch (error) {
			reject(error)
		}
	})
}

export const isAndroidInAppBrowser = () => (/Android.*(NAVER|KAKAOTALK|Whale|DaumApps|FB_IAB)/).test(navigator.userAgent)
