import { getData, downloadFile, previewFile } from '@utils/request'
import Icons from '@utils/icons'

export const initClickTriggers = (clickHandlers) => {
	document.querySelectorAll('.click-trigger').forEach((el) => {
		el.addEventListener('click', (ev) => {
			const action = ev.target.getAttribute('data-click')
			if (action) clickHandlers[action](ev)
		})
	})
}

export const initChangeTriggers = (changeHandlers) => {
	document.querySelectorAll('.change-trigger').forEach((el) => {
		el.addEventListener('change', (ev) => {
			const action = ev.target.getAttribute('data-change')
			if (action) changeHandlers[action](ev)
		})
	})
}

export const initAutoClosableElements = () => {
	document.body.addEventListener('click', (ev) => {
		if (!ev.target.closest('.auto-closable')) {
			document.getElementById('overlay').classList.add('hidden')
			document.body.classList.remove('overflow-hidden')
			document.querySelectorAll('.auto-closable').forEach((el) => {
				el.classList.add('hidden')
			})
		}
	})
}

export const handleGnbOnScroll = () => {
	const scrollTop = document.documentElement.scrollTop
	const navBar = document.getElementById('nav_bar')
	let targetScrollTop = 150

	if (/articles\/[0-9]+/.test(document.URL)) {
		navBar.querySelector('.nav-bar-content.article-title').classList.toggle('invisible', scrollTop < 140)
		navBar.querySelector('.nav-bar-content.categories').classList.toggle('invisible', scrollTop >= 140)
	} else if (location.pathname === '/') {
		navBar.querySelector('.logo-item').classList.toggle('hidden', scrollTop < 140)
		navBar.querySelector('.nav-category-list').classList.toggle('trans', scrollTop >= 130)
	}
}

export const initIntersectionObserver = (list) => {
	const loadingIndicator = list.closest('.article-list-section').querySelector('#loading-indicator')

	if (!list.classList.contains('scrollable')) return

	let page = 2 

	const loadMoreItems = async () => {
		const params = new URLSearchParams(window.location.search)
		params.set('page', page)

		const requestUrl = `${window.location.pathname}?${params.toString()}`

		const response = await getData(requestUrl, false)
		list.insertAdjacentHTML('beforeend', response.articleItemsHtml)

		if (response.existNext) {
		} else {
			list.classList.remove('scrollable')
		}

		page++

		loadingIndicator.style.visibility = 'hidden'
	}

	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting && list.classList.contains('scrollable')) {

			loadingIndicator.style.visibility = 'visible'

				setTimeout(() => {
					loadMoreItems()
				}, 900)
			}
		})
	}, {
		root: null,
		threshold: 0.9
	})

	observer.observe(loadingIndicator)
}

export const initAttachments = () => {
	document.querySelectorAll('.se-attachment-item').forEach((el) => {
		let attachmentId
		const match = el.className.match(/se-attachment-(\d+)/)
		if (match && match[1]) {
			attachmentId = match[1]
		} else {
			return
		}

		const isPreviewable = el.classList.contains('se-attachment-previewable')
		const previewButtonHtml = `<button type='button' class='se-attachment-preview-button' data-action='preview-attachment' title='미리보기'>${Icons.preview}</button>`
		const downloadButtonHtml = `<button type='button' class='se-attachment-download-button' data-action='download-attachment' title='다운로드'>${Icons.download_file}</button>`
		const buttonsHtml = `${isPreviewable ? previewButtonHtml : ''}${downloadButtonHtml}`

		el.insertAdjacentHTML('beforeend', buttonsHtml)

		el.addEventListener('click', async (ev) => {
			const action = ev.target.getAttribute('data-action')
			if (!action) return

			if (action === 'download-attachment') {
				await downloadFile(`/clips/${attachmentId}/download`)
			} else if (action === 'preview-attachment') {
				await previewFile(`/clips/${attachmentId}/preview`)
			}
		})
	})
}
