import Turbolinks from 'turbolinks'
import 'lazysizes'

import { 
	initClickTriggers,
	initChangeTriggers,
	initAutoClosableElements,
	initIntersectionObserver,	
	handleGnbOnScroll,
	throttle,
	isPWASupported,
	initAttachments,
	isAndroidInAppBrowser
} from '@utils'

import * as clickHandlers from './clickHandlers'
import * as changeHandlers from './changeHandlers'

import { checkNotificationPermission, showChromeRedirectBanner } from 'packs/utils/notification' 

Turbolinks.start()

document.addEventListener('turbolinks:load', async () => {
	if (window.innerWidth > 600) document.addEventListener('scroll', () => throttle(handleGnbOnScroll))

	initClickTriggers(clickHandlers)
	initChangeTriggers(changeHandlers)
	initAutoClosableElements()
	initAttachments()

	if (isAndroidInAppBrowser()) showChromeRedirectBanner()
	if (isPWASupported()) checkNotificationPermission()

	const infiniteScrollList = document.getElementById('infinite-scroll-list')
	if (infiniteScrollList) initIntersectionObserver(infiniteScrollList)
})
