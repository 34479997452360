import { postData, openWindow } from '@utils/request'
import { validateEmail } from '@utils/validation'

export const confirmUnsubscribe = async (ev) => {
	const token = ev.target.getAttribute('data-token')
	const response = await postData(`/newsletters/unsubscribe/${token}`)
	const pageContainer = ev.target.closest('.x-page-container')

	const confirmModal = pageContainer.querySelector('.confirm-modal')
	const completeModal = pageContainer.querySelector('.complete-modal')
	confirmModal.classList.add('hidden')
	completeModal.classList.remove('hidden')
}

export const toggleSidebarNav = (ev) => {
	ev.stopPropagation()

	const sidebarNav = ev.target.closest('header').querySelector('.mobile-sidebar-nav')
	sidebarNav.classList.toggle('hidden')
	document.getElementById('overlay').classList.toggle('hidden')
	document.body.classList.toggle('overflow-hidden')
}


export const showSearchForm = (ev) => {
	ev.stopPropagation()

	const searchForm = ev.target.closest('.search-form-wrapper').querySelector('.search-form')
	const searchInput = searchForm.querySelector('.search-input')
	searchForm.classList.remove('hidden')
	searchInput.focus()
}

export const hideSearchForm = (ev) => {
	const searchForm = ev.target.closest('.search-form-wrapper').querySelector('.search-form')
	searchForm.classList.add('hidden')
}

const showValidationMessage = (messageHolder, message) => {
	messageHolder.classList.remove('hidden')
	messageHolder.textContent = message 
}

export const submitNewsletterForm = async (ev) => {
	const form = ev.target.closest('.newsletter-form')
	const checkboxInput = form.querySelector('.checkbox-input')

	const emailInput = form.querySelector('.email-input')
	const email = emailInput.value.trim()

	const messageHolder = form.querySelector('.message-holder') 

	if (!checkboxInput.checked) {
		messageHolder.textContent = '개인 정보 수집 및 이용에 동의해주세요'
		messageHolder.classList.remove('hidden')
		return
	}

	if (!validateEmail(email)) {
		messageHolder.textContent = '이메일 주소를 확인해주세요'
		messageHolder.classList.remove('hidden')
		emailInput.focus()
		return
	}

	try {
		const response = await postData('/newsletters/subscribe', { email: email })
		const modal = document.getElementById('nw-modal')
		modal.classList.remove('hidden')

		document.getElementById('overlay').classList.remove('hidden')
		document.body.classList.add('overflow-hidden')

		emailInput.value = ''
		checkboxInput.checked = false
	} catch(error) {
		console.log(error)
		messageHolder.textContent = error.message 
		messageHolder.classList.remove('hidden')
	}
}

export const shareArticle = (ev) => {
	ev.target.closest('.share-buttons-container').classList.add('hidden')

	const platform = ev.target.getAttribute('data-platform')

	switch (platform) {
		case 'facebook': {
			const url = encodeURIComponent(document.URL)
			openWindow(`https://www.facebook.com/sharer/sharer.php?u=${url}`, 500, 500)

			break
		}
		case 'x': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://twitter.com/intent/tweet?url=${url}&text=${title}`, 500, 500)

			break
		}
		case 'linkedin': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`, 500, 500)

			break
		}
		case 'telegram': {
			const url = encodeURIComponent(document.URL)
			const title = encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'))
			openWindow(`https://t.me/share?url=${url}&text=${title}`, 500, 500)

			break
		}
		case 'url': {
			if (navigator.clipboard) {
				(async() => {
					await navigator.clipboard.writeText(location.href)
					alert('URL이 복사되었습니다.')
				})()
			}

			break
		}
	}
}

export const closeModal = (ev) => {
	const modal = ev.target.closest('.custom-modal')
	modal.classList.add('hidden')

	document.getElementById('overlay').classList.add('hidden')
	document.body.classList.remove('overflow-hidden')
}

export const showShareButtonsContainer = (ev) => {
	ev.stopPropagation()

	ev.target.parentElement.querySelector('.share-buttons-container').classList.remove('hidden')
}
