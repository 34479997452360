import { isIndexedDBConnectable } from '@utils'

const markBannerAsShown = (storageKey, expiryDay = 1) => {
	const now = new Date()
	const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + expiryDay).getTime()

	localStorage.setItem(storageKey, endOfDay)
}

const canShowBanner = (storageKey) => {
	const bannerExpiration = localStorage.getItem(storageKey)
	const now = new Date().getTime()

	if (!bannerExpiration || now > bannerExpiration) return true
	
	return false
}

const requestNotificationPermission = async () => {
	try {
		switch (await Notification.requestPermission()) {
			case 'granted': {
				const fcm = (await import('packs/utils/fcm')).default
				fcm.init()

				const modal = document.getElementById('pm-modal')
				modal.classList.remove('hidden')

				document.getElementById('overlay').classList.remove('hidden')
				document.body.classList.add('overflow-hidden')

				break
			}
			case 'default': {
				markBannerAsShown('subscriptionBannerShown', 1)
			}
			case 'denied': {
				// 모든 쿠키 삭제
				//location.reload()
			}
		}
	} catch(e) {
		console.log('An error occurred while request permission. ', e)
	}
}

export const checkNotificationPermission = async () => {
	if (!await isIndexedDBConnectable()) return

	const showSubscriptionBanner = (pushSubscriptionBanner) => {
		pushSubscriptionBanner.classList.remove('hidden')

		pushSubscriptionBanner.addEventListener('click', (ev) => {
			const action = ev.target.getAttribute('data-click')
			if (action === 'subscribeNow') {
				pushSubscriptionBanner.classList.add('hidden')
				requestNotificationPermission()
			} else if (action === 'closeBanner') {
				pushSubscriptionBanner.classList.add('hidden')

				markBannerAsShown('subscriptionBannerShown', 1)
			}
		})
	}

	switch(Notification.permission) {
		case 'granted': {
			const fcm = (await import('packs/utils/fcm')).default
			fcm.init()

			break
		}
		case 'default': {
			if (!canShowBanner('subscriptionBannerShown')) return

			localStorage.removeItem('fcmDeviceToken')

			const pushSubscriptionBanner = document.getElementById('push-subscription-banner')
			if (pushSubscriptionBanner) showSubscriptionBanner(pushSubscriptionBanner)

			break
		}
		case 'denied': {
			localStorage.removeItem('fcmDeviceToken')

			break
		}
	}
}

export const showChromeRedirectBanner = () => {
	if (!canShowBanner('chromeRedirectBannerShown')) return

	const chromeRedirectBanner = document.getElementById('chrome-redirect-banner')

	chromeRedirectBanner.classList.remove('hidden')
	chromeRedirectBanner.addEventListener('click', (ev) => {
		const action = ev.target.getAttribute('data-click')
		if (action === 'redirectNow') {
			markBannerAsShown('chromeRedirectBannerShown', 100)
			location.href = `intent://${location.href.replace(/https?:\/\//i, '')}#Intent;scheme=http;package=com.android.chrome;end`
		} else if (action === 'closeBanner') {
			chromeRedirectBanner.classList.add('hidden')
			markBannerAsShown('chromeRedirectBannerShown', 1)
		}
	})
}
